import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import qs from 'qs';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const [userIdentity, setUserToken] = useState<string>(
    decodeURI(
      user?.displayName || (qs.parse(window.location.search, { ignoreQueryPrefix: true }).userIdentity as string) || ''
    )
  );
  const [name, setName] = useState<string>(
    decodeURI(user?.displayName || (qs.parse(window.location.search, { ignoreQueryPrefix: true }).name as string) || '')
  );
  const [roomName, setRoomName] = useState<string>(
    decodeURI((qs.parse(window.location.search, { ignoreQueryPrefix: true }).roomName as string) || '')
  );
  const [colorStyle, setColorStyle] = useState<string>(
    decodeURI((qs.parse(window.location.search, { ignoreQueryPrefix: true }).colorStyle as string) || '')
  );
  const [step, setStep] = useState(name && roomName ? Steps.deviceSelectionStep : Steps.roomNameStep);
  const [mediaError, setMediaError] = useState<Error>();
  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    // @ts-ignore
    if (!window.location.origin.includes('twil.io') && !window.STORYBOOK_ENV) {
      window.history.replaceState(
        null,
        '',
        window.encodeURI(`${process.env.REACT_APP_BASE_URL || ''}/room/${roomName}${window.location.search || ''}`)
      );
    }
    setStep(Steps.deviceSelectionStep);
  };

  return (
    <IntroContainer colorStyle={colorStyle}>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName}
          setName={setName}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
          colorStyle={colorStyle}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen
          userIdentity={userIdentity}
          name={name}
          roomName={roomName}
          setStep={setStep}
          colorStyle={colorStyle}
        />
      )}
    </IntroContainer>
  );
}
