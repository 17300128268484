import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import Swoosh from './swoosh';
import VideoLogo from './VideoLogo';
import TwilioLogo from './TwilioLogo';
import { useAppState } from '../../state';
import UserMenu from './UserMenu/UserMenu';
import { useLocation } from 'react-router-dom';
import { backgroundConfig } from '../VideoProvider/useBackgroundSettings/useBackgroundSettings';
import { Filter, Style } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgb(40, 42, 43)',
    height: '100%',
  },
  container: {
    position: 'relative',
    flex: '1',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  innerContainer: {
    display: 'flex',
    width: '888px',
    boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: '100%',
      width: '100%',
      margin: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      borderRadius: '8px',
    },
  },
  swooshContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: Swoosh,
    backgroundSize: 'cover',
    width: '296px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100px',
      backgroundPositionY: '140px',
    },
  },
  swooshOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#191949', // Define a cor do swoosh
    opacity: 0.8, // Define a transparência do swoosh
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100px',
      backgroundPositionY: '140px',
    },
  },
  swooshOCOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#EF6C22', // Define a cor do swoosh
    opacity: 0.8, // Define a transparência do swoosh
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100px',
      backgroundPositionY: '140px',
    },
  },
  logoContainer: {
    position: 'absolute',
    width: '210px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      width: '90%',
      textAlign: 'initial',
      '& svg': {
        height: '64px',
      },
    },
  },
  twilioLogo: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '20px',
  },
  content: {
    background: 'white',
    width: '100%',
    padding: '4em',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '2em',
      height: '100%',
    },
  },
  title: {
    color: 'white',
    margin: '1em 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '1.1rem',
    },
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
  colorStyle?: string;
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();
  const { user } = useAppState();
  const location = useLocation();

  console.log(`cor: ` + props.colorStyle);

  return (
    <div className={classes.background}>
      {user && location.pathname !== '/login' && <UserMenu />}
      <div className={classes.container}>
        <div className={`${classes.innerContainer}`}>
          <div className={`${classes.swooshContainer}`}>
            <div
              className={`${props.colorStyle === 'cuidodevc' && classes.swooshOverlay} ${props.colorStyle === 'oc' &&
                classes.swooshOCOverlay}`}
            ></div>
            <div className={classes.logoContainer}>
              <VideoLogo colorStyle={props.colorStyle} />
              <Typography variant="h6" className={classes.title}>
                Video conferência
              </Typography>
            </div>
          </div>
          <div className={classes.content}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default IntroContainer;
