import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React from 'react';
import { Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useHelpContext from '../../../hooks/useHelpContext/useHelpContext';

const useStyles = makeStyles((theme: Theme) => {});

export default function ToggleHelpButton() {
  const helpContext = useHelpContext();
  const setHelpContextOpen = () => {
    helpContext.setModalState(true);
  };
  return (
    <Button startIcon={<HelpOutlineIcon />} onClick={setHelpContextOpen}>
      Ajuda
    </Button>
  );
}
