import React, { useEffect, useState } from 'react';

import {
  DialogContent,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  Button,
  Theme,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useBitrateTest from '../../state/diagnostics/useBitrateTest/useBitrateTest';
import usePreflightTest from '../../state/diagnostics/usePreflightTest/usePreflightTest';

import { PreflightTestReport } from 'twilio-video';
import { MediaConnectionBitrateTest } from '@twilio/rtc-diagnostics';
import NetworkDiagnosticsReport from './NetworkDiagnosticsReport';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '600px',
    minHeight: '400px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
  },
  button: {
    float: 'right',
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  joinButtonsWhiteLabelCustomColor: {
    backgroundColor: '#191949',
    '&:hover': {
      backgroundColor: '#111133',
    },
  },
  joinButtonsOCCustomColor: {
    backgroundColor: '#EF6C22',
    '&:hover': {
      backgroundColor: '#FE6013',
    },
  },
}));

function AlertError({ error }: any) {
  if (error?.message === 'Failed to fetch') error = new Error('Sem conexão com a internet');
  if (error)
    return (
      <Alert severity="error" style={{ marginTop: '1em' }}>
        {error?.message || String(error) || 'Erro inesperado'}
      </Alert>
    );
  return <></>;
}

export default function NetworkDiagnostics({
  colorStyle,
  open,
  onClose,
}: {
  colorStyle?: string;
  open: boolean;
  onClose: () => void;
}) {
  const classes = useStyles();
  const [bitrateTestRunning, setBitrateTestRunning] = useState(false);
  const [preflightTestRunning, setPreflightTestRunning] = useState(false);
  const [bitrateTestReport, setBitrateTestReport] = useState<MediaConnectionBitrateTest.Report>();
  const [preflightTestReport, setPreflightTestReport] = useState<PreflightTestReport>();
  const [testError, setTestError] = useState();

  const { startPreflightTest } = usePreflightTest({
    onFinish: () => setPreflightTestRunning(false),
    onStart: () => setPreflightTestRunning(true),
    setError: error => {
      setTestError(error);
    },
    setReport: setPreflightTestReport,
  });
  const { startBitrateTest } = useBitrateTest({
    onFinish: () => setBitrateTestRunning(false),
    onStart: () => setBitrateTestRunning(true),
    setError: error => {
      setTestError(error);
    },
    setReport: setBitrateTestReport,
    duration: 15000,
  });

  const startTests = () => {
    setTestError(undefined);
    setPreflightTestReport(undefined);
    setBitrateTestReport(undefined);
    startBitrateTest();
    startPreflightTest();
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>Teste de conexão</DialogTitle>
      <Divider />

      <DialogContent className={classes.container}>
        <Typography variant="body1" gutterBottom>
          Verifique se sua conexão com a internet está boa para fazer a videochamada.
        </Typography>
        {bitrateTestRunning || preflightTestRunning ? (
          <Button
            color="primary"
            variant="contained"
            startIcon={<CircularProgress size={20} color="secondary" />}
            disabled
          >
            Aguarde um momento
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={startTests}
            className={`${colorStyle === 'cuidodevc' && classes.joinButtonsWhiteLabelCustomColor} ${colorStyle ===
              'oc' && classes.joinButtonsOCCustomColor}`}
          >
            Iniciar testes
          </Button>
        )}
        <AlertError error={testError} />
        <NetworkDiagnosticsReport bitrateTestReport={bitrateTestReport} preflightTestReport={preflightTestReport} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          className={`${classes.button} ${colorStyle === 'cuidodevc' &&
            classes.joinButtonsWhiteLabelCustomColor} ${colorStyle === 'oc' && classes.joinButtonsOCCustomColor}`}
          onClick={onClose}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
